import React from "react"
import debounce from "lodash/debounce"
import classNames from "classnames"
import PostCard from "../PostCard/PostCard"
// import { get } from '../../utilities/get.js';
import { UserDataContext } from "../../context/UserDataContext"
import "./main.scss"
import { get } from "../../utilities/get"

const Main = () => {
  const [userData] = React.useContext(UserDataContext)
  //
  const [isMessageReveled, setMessageReveled] = React.useState(false)
  // TODO: Add logic reporting full scroll event
  // const [isBottomReached, setBottomReached] = React.useState(false)
  const message = React.useRef()

  // TODO: This works, if the ID is not dynamic. So maybe it need to be async with a loader in the future.
  // TODO: We should also abstract the first segment f the URL so it work locally and on the remote server with editing.
  /*  React.useEffect(() => {
    // get data from GitHub api
    // fetch(`https://api.github.com/repos/gatsbyjs/gatsby`)
    fetch(`http://christmas-2001.gd:8888/static/log/FirstName.php?u=${userData.id}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // setStarsCount(resultData.stargazers_count)
        console.log(resultData)
      }) // set data for the number of stars
  }, []) */

  React.useLayoutEffect(() => {
    const calcMessageReveal = () => {
      const { pageYOffset } = window
      const pageInnerHeight = window.innerHeight
      const messageYOffset = message.current.getBoundingClientRect().top
      // console.log('window.pageYOffset: ' + window.pageYOffset)
      // console.log('window.innerHeight: ' + window.innerHeight)

      if (messageYOffset < pageYOffset + pageInnerHeight) {
        setMessageReveled(true)
      } else {
        setMessageReveled(false)
      }
    }

    const scrollEventDebouncer = debounce(() => {
      calcMessageReveal()
    }, 100)

    if (typeof window !== "undefined") {
      window.addEventListener("load", calcMessageReveal())
      window.addEventListener("scroll", scrollEventDebouncer)
      window.addEventListener("resize", scrollEventDebouncer)
    }
    // Remove listener (like componentWillUnmount)
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("load", calcMessageReveal())
        window.removeEventListener("scroll", scrollEventDebouncer)
        window.removeEventListener("resize", scrollEventDebouncer)
      }
    }
  }, [])

  const coraCookieClick = () => {
    get(`static/log/LogEvent.php?u=${userData.id}&e=cora cookie clicked`)
  }
  const lilyCookieClick = () => {
    get(`static/log/LogEvent.php?u=${userData.id}&e=lily cookie clicked`)
  }
  return (
    <main className="Main">
      <div className="Main-postCard">
        <div className="Main-postCardInner">
          <PostCard />
        </div>
      </div>
      <div className="Main-message">
        <div
          ref={message}
          className={classNames("Main-messageInner", {
            "is-visible": isMessageReveled === true,
          })}
        >
          <p>Greetings 👋</p>

          <p>
            It&apos;s been another fortunate year here in Hopkinton for the
            Gavula household.
          </p>
          <p>
            The kids seem to be growing up faster and faster. Cora at 10 is
            acting more and more like a teenager, and Lily at 8 has decided she
            is too big for Barbies. We&apos;re just not ready for what comes
            next 😬.
          </p>
          <p>
            Both of the girls are staying busy in the 5th and 3rd grades
            respectively with a slew of extracurricular activities. Cora has
            taken up the clarinet, volleyball 🏐 and skiing. Meanwhile Lily
            continues to play soccer ⚽️ and basketball.
          </p>

          <p>
            Oh, and this year both of the kiddos are participating in Girl Scouts
            and would be happy to take your cookie order 🍪! You can support{" "}
            <a
              href="https://app.abcsmartcookies.com/#/social-link-landing/0138dbb9-fed2-44f8-9d4d-45de08959bdf"
              target="_blank"
              rel="noreferrer"
              onClick={() => coraCookieClick()}
            >
              Cora&apos;s troop here
            </a>{" "}
            or{" "}
            <a
              href="https://app.abcsmartcookies.com/#/social-link-landing/08132d49-370e-4b0e-9a04-0f7cc77773c6"
              target="_blank"
              rel="noreferrer"
              onClick={() => lilyCookieClick()}
            >
              Lily&apos;s troop here.
            </a>{" "}
            We&apos;ll deliver for free if you live nearby, or select
            &quot;Direct Ship&quot; to have them mailed.
          </p>

          <p>
            As for the adults… When Jenna is not busy moonlighting as a “cookie
            coordinator” she can still be found working for Bose🎧 - where she
            accepted a full time role as the visual designer with their
            Marketing Accelerator team.
          </p>

          <p>
            Meanwhile John continues to lead a double life as a contract UX
            designer by day, with a youth soccer coaching habit on evenings and
            weekends.
          </p>
          <p>
            All told, 2022 was a fun year full of small milestones and big
            memories. We feel blessed and wish you and yours love and peace in ‘23.
          </p>
          <p>- John, Jenna, Cora, Lily and Finn 🐾</p>
        </div>
      </div>
    </main>
  )
}

export default Main
