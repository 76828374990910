import React from "react"
import { useMount, useDeepCompareEffect } from "react-use"
import VisibilitySensor from "react-visibility-sensor"
// import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import Header from "../components/Header/Header"
import Main from "../components/Main/Main"
import TextPipe from "../components/TextPipe/TextPipe"
import PrintBanner from "../components/PrintBanner/PrintBanner"
import PrintableFile from "../components/downloads/GavulaXMas--2022.pdf"
import { get } from "../utilities/get"
import { UserDataContext } from "../context/UserDataContext"

import "../assets/foundation.scss"
import "./layoutCard.scss"
import Footer from "../components/Footer/Footer"

const LayoutCard = () => {
  // eslint-disable-next-line no-unused-vars
  const [userData, updateUserData] = React.useContext(UserDataContext)
  const [isVisible, setIsVisible] = React.useState(true)
  const [viewportWidth, setViewportWidth] = React.useState(undefined)
  const isMounted = React.useRef(false)

  useMount(() => {
    if (typeof window !== "undefined") {
      // Collect User ID from URL parameter.
      const urlString = window.location.href
      const url = new URL(urlString)
      const params = url.searchParams.get("u")

      updateUserData({
        id: params,
      })
    }
  })
  // Sets viewport width variable
  const setWidth = debounce(() => {
    setViewportWidth(window.innerWidth)
  }, 100)
  // Listens to Viewport width variable changes and logs them
  React.useEffect(() => {
    if (viewportWidth !== undefined) {
      get(
        `static/log/LogEvent.php?u=${userData.id}&e=viewport ${viewportWidth}px wide`
      )
    }
  }, [userData, viewportWidth])
  //
  React.useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("load", setWidth)
      window.addEventListener("resize", setWidth)
    }
    // Remove listener (like componentWillUnmount)
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("load", setWidth)
        window.removeEventListener("resize", setWidth)
      }
    }
  }, [])
  /* React.useEffect(() => {
    // get data from GitHub api
    // fetch(`https://api.github.com/repos/gatsbyjs/gatsby`)
    fetch(`http://christmas-wpgraphql-2001.gd:8888/wp-json/wp/v2/greeting_card/17`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // setStarsCount(resultData.stargazers_count)
        console.log(resultData)
      }) // set data for the number of stars
  }, []) */

  // Tracks if the card is opened
  useDeepCompareEffect(() => {
    if (userData.id !== undefined) {
      get(`static/log/LogEvent.php?u=${userData.id}&e=open`)
    }
  }, [userData])

  // Tracks if the print button enters the viewportq
  React.useEffect(() => {
    if (isMounted.current) {
      if (isVisible) {
        get(`static/log/LogEvent.php?u=${userData.id}&e=visible print`)
        // console.log("visible print")
      }
    } else {
      isMounted.current = true
    }
  }, [userData.id, isVisible])

  // Query by ID
  // https://www.wpgraphql.com/docs/posts-and-pages/

  return (
    <>
      <Header>
        The Gavula Family <TextPipe /> 2022
      </Header>
      <Main />
      <VisibilitySensor onChange={() => setIsVisible(!isVisible)}>
        <PrintBanner file={PrintableFile} />
      </VisibilitySensor>
      <Footer />
    </>
  )
}

LayoutCard.propTypes = {
  // children: PropTypes.node,
}

export default LayoutCard
